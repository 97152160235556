<template>
  <div>
    <el-form :model="search">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-form-item label="请输入员工姓名">
            <el-input v-model="search.name" placeholder="请输入员工姓名" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入员工手机号">
            <el-input v-model="search.phone" placeholder="请输入员工手机号" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请选择用户状态">
            <el-select placeholder="请选择用户状态" v-model="search.status">
              <el-option label="正常" :value="0" />
              <el-option label="已冻结" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请选择审核状态">
            <el-select placeholder="请选择审核状态" v-model="search.sh_status">
              <el-option label="待审核" :value="0" />
              <el-option label="审核通过" :value="1" />
              <el-option label="审核不通过" :value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item>
            <el-button type="primary" @click="onSearchBtn" :icon="Search"
              >搜索</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格 -->

    <el-table
      :data="listData"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column prop="number" label="员工编码" width="100px" />
      <el-table-column label="头像" width="120px">
        <template #default="{ row, $index }">
          <el-image
            @click="previewUrl($index)"
            style="width: 50px; height: 50px"
            :preview-teleported="true"
            :src="'http://hxdh.yslts.com/' + row.pic"
            :initial-index="imgIndex"
            fit="cover"
            :preview-src-list="srcList"
          />
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="昵称" width="100px" />
      <el-table-column prop="name" label="姓名" width="100px" />
      <el-table-column prop="phone" label="授权手机号" width="120px" />
      <el-table-column prop="staff_phone" label="员工手机号" width="120px" />
      <el-table-column prop="address" label="地址" width="200px" />
      <el-table-column label="二维码" width="120px">
        <template #default="{ row, $index }">
          <el-image
            @click="previewUrl($index)"
            style="width: 50px; height: 50px"
            :preview-teleported="true"
            :src="'http://hxdh.yslts.com/' + row.qrcode"
            :initial-index="imgIndex"
            fit="cover"
            :preview-src-list="srcList"
          />
        </template>
      </el-table-column>
      <el-table-column label="用户状态" width="100px">
        <template v-slot="{ row }">
          {{ row.status ? '已冻结' : '正常' }}
        </template>
      </el-table-column>
      <el-table-column label="审核状态" width="100px">
        <template v-slot="{ row }">
         {{row.sh_status==0?"待审核" : row.sh_status==1?"审核通过" :"审核不通过"}}
        </template>
      </el-table-column>
      <el-table-column prop="sh_time" label="审核时间" width="150px" />
      <el-table-column prop="sh_demo" label="审核驳回原因" width="150px" />
      <el-table-column prop="add_time" label="添加时间" width="150px" />
      <el-table-column fixed="right" label="操作" width="200">
        <template #default="{ row }">
          <el-button
            v-if="row.status !== 1"
            type="danger"
            size="small"
            @click="deleteBtn(row.id)"
          >
            冻结
          </el-button>

          <el-button
            v-else
            type="primary"
            size="small"
            @click="startBtn(row.id)"
          >
            启用
          </el-button>

          <el-button
            type="primary"
            size="small"
            @click="onEditBtn(row.id)"
            v-if="row.sh_status === 0"
          >
            审核
          </el-button>
          <el-button type="primary" size="small" @click="onEditNameBtn(row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <shenhe
      v-model="detailVisible"
      :orderid="id"
      :onListData="onListData"
    ></shenhe>
    <editname
      v-model="editstaffname"
      :staffinfo="staffinfo"
      :onListData="onListData"
    ></editname>
  </div>
</template>

<script setup>
// articleListAPI
import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import shenhe from './shenhe.vue'
import editname from './editname.vue'
import { staffListAPI, deletestaffAPI, startstaffAPI } from '@/api/account'
const editstaffname = ref(false)

/**
 *  表单 S
 */
// 表单数据源
const search = ref({
  class_id: '',
  title: '',
  page: 1,
  limit: 10,
  total: 0
})
// 搜索
const onSearchBtn = () => {
  onListData()
}

/**
 *  表单 E
 */

/*
 *  表格： S
 */

// 列表
const listData = ref([])
const onListData = async () => {
  const { data, total } = await staffListAPI(search.value)
  search.value.total = total
  listData.value = data
}
onListData()

// 冻结
const deleteBtn = async (id) => {
  await deletestaffAPI({ id })
  ElMessage.success('冻结账号成功！')
  onListData()
}
// 启用
const startBtn = async (id) => {
  await startstaffAPI({ id })
  ElMessage.success('启用账号成功！')
  onListData()
}

// 审核
const detailVisible = ref(false)
const id = ref('')
const onEditBtn = (orderid) => {
  detailVisible.value = true
  id.value = orderid
}
// 修改姓名
const staffinfo = ref({})
const onEditNameBtn = (row) => {
  staffinfo.value = row
  console.log(staffinfo.value)
  editstaffname.value = true
}
/*
 *  表格： E
 */

/**
 *  分页 S
 */

// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onListData()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onListData()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped></style>
